import {Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger} from "@nextui-org/react";
import {CashCoin} from "react-bootstrap-icons";
import {handlePaymentOptions} from "./handlePaymentOptions";

export const PaymentDropdown = ({ userInfo, reference, customerId, transNo, transType, balance, currency, setWaitingProcess, setRef, handleOpen }) => {
    return (
        <Dropdown backdrop="blur">
            <DropdownTrigger>
                <Button color="warning" variant="flat" size="md">
                    <CashCoin/> Pagar
                </Button>
            </DropdownTrigger>

            <DropdownMenu variant="faded" aria-label="Static Actions">
                {userInfo.paymentMethods.filter(option => option.status).map((option) => (
                    (option.type === 'oxxo') && balance > 10 && balance <= 10000 && currency === 'MXN' ?
                        <DropdownItem key={option.type} onClick={() =>
                            handlePaymentOptions(
                                option,
                                reference,
                                customerId,
                                transNo,
                                transType,
                                balance,
                                currency,
                                userInfo,
                                setWaitingProcess,
                                setRef,
                                handleOpen
                            )}>
                            {option.name}
                        </DropdownItem> :
                    ((option.type === 'card') && balance > 10 && balance < 1000000 && currency === 'MXN') ||
                    (option.type === 'cash' || option.type === 'transfer') && balance > 0 ?
                        <DropdownItem key={option.type} onClick={() =>
                            handlePaymentOptions(
                                option,
                                reference,
                                customerId,
                                transNo,
                                transType,
                                balance,
                                currency,
                                userInfo,
                                setWaitingProcess,
                                setRef,
                                handleOpen
                            )}>
                            {option.name}
                        </DropdownItem> : null
                ))}
            </DropdownMenu>
        </Dropdown>
    );
};
