import {typeTransactions} from "./typeTransactions";
import utils from "./utils";
export default function endPointsHandler(user, filters = {}) {

    const API_BASE_URL = `${utils().protocol+user.host}/`;
    const pagination = utils().pagination;
    let endPoint;

    let type = '';
    if (filters.filterType === '0'){
        // Filtra el arreglo para excluir el tipo '0'
        const filteredTypes = typeTransactions.filter(transaction => transaction.type !== '0');
        // Mapea el arreglo filtrado a un arreglo de cadenas 'filterType=type'
        const typeStrings = filteredTypes.map(transaction => `filterType[]=${transaction.type}`);
        // Une el arreglo de cadenas en una sola cadena separada por '&'
        type = typeStrings.join('&');
    } else {
        type = `filterType=${filters.filterType}`;
    }

    const transactionsFilters = [
        `ref_no=${filters.refNo || ''}`,
        `customer_id=${user.cid || ''}`,
         type,
        `since_date=${filters.sinceDate || ''}`,
        `until_date=${filters.untilDate || ''}`,
        `skip=${(filters.page - 1) * pagination}`,
        `limit=${pagination}`
    ].join('&');

    const preInvoicesFilters = [
        `debtor_no=${user.cid}`,
        `skip=${(filters.page - 1) * pagination}`,
        `limit=${pagination}`
    ].join('&');

    endPoint = {
        transactions: `${API_BASE_URL}/api/v3/sales/transactions?${transactionsFilters}`,
        preInvoices: `${API_BASE_URL}/api/v3/sales/sales_orders_uninvoiced?${preInvoicesFilters}`,
        getXML: `${API_BASE_URL}/api/cfdi/xml/${filters}`,
        getPdf: `${API_BASE_URL}/api/v3/sales/get_pdf`
    };

    return endPoint;
}
