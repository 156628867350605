import utils from "../data/utils";
import axios from "axios";
import {toast} from "react-toastify";
import {numDecimals} from "../data/tools";

const postPayment = async (endPoint, data, token) => {
    try {
        const response = await axios.post(endPoint, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Hubo un error al generar la sesión de pago: ', error);
        toast.error("Hubo un error al generar la sesión de pago");
    }
};

export const handlePayment = async ({type, reference, customerId, transNo, transType, balance, currency, paymentMethod, host, token, setWaitingProcess, setRef, handleOpen}) => {

    let product = {
        reference: reference,
        customerId: customerId,
        transNo: transNo,
        transType: transType,
        balance: numDecimals((balance * 100), 2), //Redondear a dos decimales la cantidad independientemente al número de decimales del cliente, ya que stripe únicamente acepta cantidades con dos decimales
        currency: currency
    };

    setWaitingProcess(true);

    if (type === "stripe") {
        const endPoint = `${utils().protocol}${host}/modules/portal_cliente/api/v3/payment`;
        const data = {product, paymentMethod};
        const response = await postPayment(endPoint, data, token);

        const session = response.session;
        paymentMethod === 'oxxo' ? window.open(session, '_blank') : window.location.href = session;

        setWaitingProcess(false);
    } else if (type === "transfer") {
        const endPoint = `${utils().protocol}${host}/modules/portal_cliente/api/v3/payment_account`;
        const data = {
            trans_no: transNo,
            trans_type: transType
        };
        const response = await postPayment(endPoint, data, token);

        setRef({title: 'Transferencia/SPEI', text: response.stp_ref});
        handleOpen('blur');

        setWaitingProcess(false);
    } else {
        console.error("Tipo de pago no definido: "+type);
    }
};
