import React, {createContext, useContext, useEffect, useRef, useState} from 'react';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import utils from "../auth/data/utils";

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [userInfo, setUserInfo] = useState(null);
    const navigate = useNavigate(); // Usa useNavigate para obtener la función navigate
    const prevUserInfoRef = useRef(); // Crea una referencia para almacenar el valor anterior de userInfo

    useEffect(() => {
        prevUserInfoRef.current = userInfo; // Actualiza el valor anterior de userInfo
    });

    useEffect(() => {
        const handleStorageChange = () => {
            const updatedUser = localStorage.getItem('operamUser');
            if (updatedUser && JSON.stringify(updatedUser) !== JSON.stringify(prevUserInfoRef.current)) {
                setUserInfo(JSON.parse(updatedUser));
            }
        };

        handleStorageChange();

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    const login = async (infoToken, reload) => {
        console.log(infoToken);
        try {

            const res = await axios.post (
                `${utils().protocol+infoToken.host}/modules/portal_cliente/api/v3/login`,
                {
                    host: infoToken.host,
                    pass: reload ? infoToken.newToken : infoToken.token
                }
            );

            const User = await res.data;

            if (User.errors){
                return User.errors;
            }

            // Formatea los datos del usuario
            const formattedUser = {
                host: User.host,
                token: User.token,
                newToken: User.newToken,
                expires: User.expires,
                companyId: User.company.id,
                company: User.company.name,
                companyTax: User.company.tax_id,
                cid: User.client.customer_id,
                name: User.client.CustName,
                tax: User.client.tax_id,
                paymentMethods: User.paymentOptions,
                decimals: User.decimals,
                pagination: User.pagination,
            };

            // Guarda los datos del usuario en el localStorage
            localStorage.setItem('operamUser', JSON.stringify(formattedUser));

            // Actualiza el estado del usuario con los datos formateados
            setUserInfo(formattedUser);

            // Redirige a la página 'home' después de iniciar sesión
            navigate('/Inicio');

        } catch (error) {
            console.error(error);
            return error;
        }
    };

    const logout = () => {
        setUserInfo(null);
        // Borra los datos del usuario del localStorage
        localStorage.removeItem('user');
    };

    return (
        <AuthContext.Provider value={{ userInfo, setUserInfo, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
}