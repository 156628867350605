import {Spinner} from "@nextui-org/react";

export default function LTemplate1({ status = '' }) {
    return (
        <div style={{
            width: '100%',
            height: '100%',
            position: 'fixed',
            top: 0,
            left: 0,
            background: 'rgba(0, 0, 0, 0.1)',
            backdropFilter: 'blur(.1rem)',
            zIndex: 50
        }}>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '50vh'
            }}>
                <Spinner label={status} color="primary" labelColor="primary"/>
            </div>
        </div>
    );
}
