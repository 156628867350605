import React, {useEffect, useState} from 'react';
import { TableTransactions } from "../../components/tableTransactions";
import LTemplate1 from "../../../components/loading/template1";
import {useAuth} from "../../../context/AuthContext";
import {TablePreInvoices} from "../../components/tablePreInvoices";

function Page() {
    const [loadingPage, setLoadingPage] = useState(true);
    const [greeting, setGreeting] = useState('');
    const { userInfo } = useAuth()

    useEffect(() => {
        const date = new Date();
        const hour = date.getHours();

        if (hour < 12) {
            setGreeting('Buenos días');
        } else if (hour < 18) {
            setGreeting('Buenas tardes');
        } else if (hour < 24) {
            setGreeting('Buenas noches');
        } else {
            setGreeting('¡Ya es hora de dormir!');
        }

        setLoadingPage(false); // Desactivar el indicador de carga una vez que se obtiene la información
    }, []);

    return (
        <>
            {loadingPage &&
                <LTemplate1 status="Loading"/>
            }
            {userInfo && (
                <>
                <div className="my-14 lg:px-6 max-w-[95rem] mx-auto w-full flex flex-col gap-4">
                    <h3 className="text-xl font-semibold">{greeting + ', ' + userInfo.name}</h3>
                    <h3 className="text-xl font-semibold">Facturas vencidas</h3>
                    <TableTransactions type={2} userInfo={userInfo}/>
                    <h3 className="text-xl font-semibold">Prefacturas pendientes de pago</h3>
                    <TablePreInvoices userInfo={userInfo}/>
                </div>
                </>
            )}
        </>
    );
}


export default Page;
