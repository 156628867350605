import {
    Navbar,
    NavbarContent,
    User
} from "@nextui-org/react";
import React from "react";
import { BurguerButton } from "./burguer-button";
import {useAuth} from "../../context/AuthContext";

const NavbarWrapper = ({ children }) => {

    const { userInfo } = useAuth()

    return (
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
            <Navbar isBordered className="w-full"
                    classNames={{
                        wrapper: "w-full max-w-full",
                    }}>
                <NavbarContent className="md:hidden">
                    <BurguerButton />
                </NavbarContent>
                <NavbarContent className="w-full max-md:hidden"></NavbarContent>
                <NavbarContent as="div" className="items-center" justify="end">
                    <User
                        name={userInfo.name}
                        description={userInfo.tax}
                    />
                </NavbarContent>
            </Navbar>
            {children}
        </div>
    );
};

export default NavbarWrapper;