import {Spinner} from "@nextui-org/react";

export default function LTemplate2({ status = '' }) {
    return (
        <div className="w-full h-full fixed top-0 left-0 bg-background/900">
            <div className="flex justify-center items-center mt-[50vh]">
                <Spinner label={ status } color="primary" labelColor="primary"/>
            </div>
        </div>
    );
}
