import endPointsHandler from "../data/endPointsHandler";
import axios from "axios";
import {toast} from "react-toastify";

export const getTransactionsPDF = async (userInfo, type, setWaitingProcess, uuid, transNo, transType) => {
    let API_PDF = '';
    let response;

    setWaitingProcess(true);

    try {
        switch (type) {
            case 1: //PDF TIMBRADO
            case 2: //PDF DEFAULT
                API_PDF = endPointsHandler(userInfo).getPdf;
                response = await axios.post(API_PDF, {
                    trans_no: transNo,
                    type: transType
                }, {
                    headers: { Authorization: `Bearer ${userInfo.token}` },
                });
                const fileUrl = response.data.url_file_link;
                window.open(fileUrl, '_blank');
                break;
            default:
                break;
        }
        setWaitingProcess(false);
        toast.success("Archivo procesado exitosamente");
    } catch (error) {
        console.error('Hubo un error al descargar el PDF', error);
        setWaitingProcess(false);
        toast.error("Ocurrió un error al procesar el archivo");
    }
};