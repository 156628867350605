import {useEffect, useState} from "react";
import confetti from 'canvas-confetti';
import { useNavigate } from "react-router-dom";

const PaymentSuccess = () => {

    const [counter, setCounter] = useState(5);
    const navigate = useNavigate();

    const handleConfetti = () => {
        confetti({
            particleCount: 100,
            startVelocity: 30,
            spread: 360,
            origin: {
                x: Math.random(),
                // since they fall down, start a bit higher than random
                y: Math.random() - 0.2
            }
        });
    };

    useEffect(() => {
        handleConfetti();
        const timer = setTimeout(() => {
            if (counter > 0) {
                setCounter(counter - 1);
            } else {
                navigate("/Inicio");
            }
        }, 1000);
        return () => clearTimeout(timer);
    }, [counter, navigate]);

    return (
        <div className="text-3xl font-bold text-center py-10 h-screen flex items-center justify-center">
            <div>
                <span className="text-7xl">
                    🎉
                </span>
            </div>
            <div className="mt-5">
                <h3 className="text-xl font-semibold">Su pago se ha completado correctamente</h3>
                {counter > 0 && <p className="text-sm font-semibold">Redirigiendo en {counter} segundos...</p>}
            </div>
        </div>
    );
};
export default PaymentSuccess;
