import {Card, CardBody, CardFooter, CardHeader, Divider, Image, Link} from "@nextui-org/react";

export default function TemplateError1({ message = [] }) {
    return (
        <Card className="max-w-[400px]">
            <CardHeader className="flex gap-3">
                <Image
                    alt="nextui logo"
                    height={40}
                    radius="sm"
                    src="https://avatars.githubusercontent.com/u/86160567?s=200&v=4"
                    width={40}
                />
                <div className="flex flex-col">
                    <p className="text-md">Operam</p>
                    <p className="text-small text-default-500">Piloto Automatico, S.A de C.V.</p>
                </div>
            </CardHeader>
            <Divider/>
            <CardBody>
                {message}
            </CardBody>
            <Divider/>
            <CardFooter>
                <Link
                    isExternal
                    showAnchorIcon
                    href="#"
                >
                    Visit operam.mx
                </Link>
            </CardFooter>
        </Card>
    )
}