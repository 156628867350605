export const typeTransactions = [
    {
        label: "Todos",
        value: "0",
        type: "0",
    },
    {
        label: "Factura",
        value: "1",
        type: "10",
    },
    {
        label: "Pago",
        value: "3",
        type: "12",
    },
    {
        label: "Nota de crédito",
        value: "4",
        type: "11",
    },
];
