import './App.css';
import {AuthProvider, useAuth} from './context/AuthContext';
import {BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Providers } from "./providers";
import Login from './login/token';
import Home from './auth/pages/home/page';
import Transactions from "./auth/pages/transactions/page";
import PaymentSuccess from "./auth/pages/PaymentSuccess/page";
import {ErrorPage} from "./guest/pages/errorPage";
import {Layout} from "./components/layout/layout";

function App() {

    function PrivateRoute({ component: Component }) {
        let auth = useAuth();

        if (!auth.userInfo) {
            return <ErrorPage/>
        }

        // Renderiza tu componente de página aquí
        return (
            <Layout>
                <Component />
            </Layout>
        );
    }

    return (
        <Router>
            <AuthProvider>
                <Providers>
                    <Routes>
                        <Route path="/login/token/:token" element={<Login/>}/>
                        <Route path="/"             element={<PrivateRoute component={Home}/>}/>
                        <Route path="/Inicio"         element={<PrivateRoute component={Home}/>}/>
                        <Route path="/Transacciones" element={<PrivateRoute component={Transactions}/>}/>
                        <Route path="/Pago/exito" element={<PrivateRoute component={PaymentSuccess}/>}/>
                        <Route path="*" element={
                            <ErrorPage/>}
                        /> {/* Esta ruta capturará cualquier ruta no definida anteriormente */}
                    </Routes>
              </Providers>
          </AuthProvider>
      </Router>
    );
}

export default App;
