import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import LTemplate2 from "../components/loading/template2";
import {ErrorPage} from "../guest/pages/errorPage";

function Login() {
    const { token } = useParams();
    const { login } = useAuth();
    const [errors, setErrors] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchToken = async () => {
            if (token) {
                try {
                    // Decodifica el token de base64
                    const decodedToken = atob(token);

                    // Intenta parsear el token decodificado como JSON
                    const jsonToken = JSON.parse(decodedToken);

                    // Si todo va bien, inicia sesión con el token
                    const errors = await login(jsonToken);
                    if (errors) {
                        setErrors(errors);
                    }
                } catch (error) {
                    setErrors('Token inválido');
                    console.error('El token no es un JSON válido en base64:', error);
                } finally {
                    setLoading(false); // Finaliza la carga independientemente de si hubo un error o no
                }
            }
        };

        fetchToken();
    }, [token]);

    if (loading) {
        return <LTemplate2 status="Cargando datos"/>;
    }

    if (errors) {
        return <ErrorPage message={'Token invalido'}/>; // Muestra los errores si los hay
    } else {
        return <LTemplate2 status="Iniciando sesión"/>;
    }
}

export default Login;
