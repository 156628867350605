import {
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    Spinner,
    Table,
    TableBody,
    TableCell,
    TableColumn,
    TableHeader,
    TableRow, useDisclosure
} from "@nextui-org/react";
import {useCallback, useEffect, useMemo, useState} from "react";
import endPointsHandler from "../data/endPointsHandler";
import {Pagination} from "@nextui-org/react";
import LTemplate1 from "../../components/loading/template1";
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/customDatePickerWidth.css";
import utils from "../data/utils";
import {useAuth} from "../../context/AuthContext";
import moment from "moment/moment";
import { numDecimals, numFormat} from "../data/tools";
import {getTransactions} from "./getTransactions";
import {FiletypePdf} from "react-bootstrap-icons";
import {getTransactionsPDF} from "./getTransactionsPDF";
import {PaymentDropdown} from "./paymentDropdown";

export const TablePreInvoices = ({userInfo=[]}) => {

//////////////////////////////////////// GLOBALS: START
    const [page, setPage] = useState(1);
    const [loadingTable, setLoadingTable] = useState(false);
    const [data, setData] = useState([]);
    const [totalData, setTotalData] = useState(1);
    const [waitingProcess, setWaitingProcess] = useState(false);
    const {isOpen, onOpen, onClose} = useDisclosure();
    const [ref, setRef] = useState({ title: '', text: '' });
    const { login } = useAuth();
    const handleOpen = () => {
        onOpen();
    }
    const pages = useMemo(() => {
        return totalData ? Math.ceil(totalData / utils().pagination) : 0;
    }, [totalData, utils().pagination]);
//////////////////////////////////////// GLOBALS: END

    useEffect(() => {
        handleSubmit();
    }, [page, userInfo]);


//////////////////////////////////////// FUNCTIONS: START
    const handleSubmit = (event) => {
        if (event) event.preventDefault();

        let API_transactions = endPointsHandler(userInfo,
            {
                page: page
            }).preInvoices;

        getTransactions(API_transactions, userInfo, setData, setTotalData, setLoadingTable, login);
    }
//////////////////////////////////////// FUNCTIONS: END

//////////////////////////////////////// TABLE: START
    let columns = [
        {uid: "orderNo",        name: "Pedido Nº"},
        {uid: "reference",      name: "Referencia"},
        {uid: "ord_date",       name: "Fecha de pedido"},
        {uid: "delivery_date",  name: "Fecha de entrega"},
        {uid: "total",          name: "Total"},
        {uid: "curr_code",      name: "Moneda"},
        {uid: "order_no",       name: "Acciones"},
    ];

    const renderCell = useCallback((item, columnKey) => {

        const cellValue = item[columnKey];
        let orderNo = item.order_no;
        let reference = item.reference;
        let type = item.trans_type;
        let balance =  numDecimals(item.total, userInfo.decimals);
        let currency = item.curr_code;
        let customerId = item.debtor_no;

        switch (columnKey) {
            case "orderNo":
                return (
                    <div className="text-end">{orderNo}</div>
                );
            case "reference":
                return (
                    <div className="text-start">{cellValue}</div>
                );
            case "ord_date":
            case "delivery_date":
                return (
                    <div className="text-start">{moment(cellValue).format('YYYY-MM-DD')}</div>
                );
            case "total":
                return (
                    <div className="text-end">{numFormat(cellValue, userInfo.decimals)}</div>
                );
            case "curr_code":
                return (
                    <div className="text-start">{cellValue}</div>
                );
            case "order_no":
                return (
                    <div className="flex justify-end gap-2">
                        <Button isIconOnly color="danger" variant="flat" size="md"
                                onClick={() => getTransactionsPDF(userInfo, 2, setWaitingProcess, null, cellValue, type)}>
                            <FiletypePdf size={20}/>
                        </Button>
                        <PaymentDropdown
                            userInfo={userInfo}
                            reference={reference}
                            customerId={customerId}
                            transNo={cellValue}
                            transType={type}
                            balance={balance}
                            currency={currency}
                            setWaitingProcess={setWaitingProcess}
                            setRef={setRef}
                            handleOpen={handleOpen}
                        />
                    </div>
                );
            default:
                return cellValue;
        }
    }, []);
//////////////////////////////////////// TABLE: END

    return (
        <>
            {waitingProcess &&
                <LTemplate1 status="Obteniendo información"/>
            }
            <Table
                isHeaderSticky
                selectionMode="single"
                bottomContent={
                    pages > 0 ? (
                        <div className="flex w-full justify-center">
                            <Pagination
                                isCompact
                                showControls
                                showShadow
                                color="primary"
                                page={page}
                                total={pages}
                                onChange={(newPage) => setPage(newPage)}
                            />
                        </div>
                    ) : null
                }
            >
                <TableHeader columns={columns}>
                    {(column) => (
                        <TableColumn key={column.uid} className="text-center">
                            {column.name}
                        </TableColumn>
                    )}
                </TableHeader>
                <TableBody
                    emptyContent={"No hay transacciones pendientes de pago"}
                    loadingContent={<Spinner/>}
                    loadingState={loadingTable}
                    items={data}>
                    {(item) => (
                        <TableRow key={item.order_no}>
                            {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
                        </TableRow>
                    )}
                </TableBody>
            </Table>

            <Modal backdrop="blur" isOpen={isOpen} onClose={onClose}>
                <ModalContent>
                    <ModalHeader className="flex flex-col gap-1">{ref.title}</ModalHeader>
                    <ModalBody>
                        <p style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>
                            {ref.text ? ref.text : 'No se encontró información'}
                        </p>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};