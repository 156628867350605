import TemplateError1 from "../../components/errors/template1";

export function ErrorPage({message = 'Error 404: Página no encontrada'}) {
    return(
        <>
            <div className="w-full h-full fixed top-0 left-0 bg-background/900">
                <div className="flex justify-center items-center mt-[50vh]">
                    <TemplateError1 message={[message]} />
                </div>
            </div>
        </>
    );
}