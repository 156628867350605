import {
    Button,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    Select,
    SelectItem,
    Spinner,
    Table,
    TableBody,
    TableCell,
    TableColumn,
    TableHeader,
    TableRow, useDisclosure
} from "@nextui-org/react";
import {useCallback, useEffect, useMemo, useState} from "react";
import moment from 'moment';
import {subDays} from "date-fns";
import endPointsHandler from "../data/endPointsHandler";
import {typeTransactions} from "../data/typeTransactions";
import { saveAs } from 'file-saver';
import {FiletypePdf, FiletypeXml} from "react-bootstrap-icons";
import {Pagination} from "@nextui-org/react";
import axios from "axios";
import LTemplate1 from "../../components/loading/template1";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/customDatePickerWidth.css";
import {SearchIcon} from "./SearchIcon";
import utils from "../data/utils";
import {toast} from "react-toastify";
import {useAuth} from "../../context/AuthContext";
import { numDecimals, numFormat} from "../data/tools";
import {getTransactions} from "./getTransactions";
import {getTransactionsPDF} from "./getTransactionsPDF";
import {PaymentDropdown} from "./paymentDropdown";

export const TableTransactions = ({useFilter = false, type= 1, userInfo=[]}) => {

//////////////////////////////////////// GLOBALS: START
    let startReference  = "";
    let startType     = type;
    const [startDate, setStartDate] = useState( (type === 2 ? "" : subDays(new Date(), 30)));
    const [endDate, setEndDate] = useState(new Date(), 30);
    const [page, setPage] = useState(1);
    const [loadingTable, setLoadingTable] = useState(false);
    const [data, setData] = useState([]);
    const [totalData, setTotalData] = useState(1);
    const [waitingProcess, setWaitingProcess] = useState(false);
    const {isOpen, onOpen, onClose} = useDisclosure();
    const [ref, setRef] = useState({ title: '', text: '' });
    const [inputStatus, setInputStatus] = useState(false);
    const [disabledBtn, setDisabledBtn] = useState(false);
    const { login } = useAuth();
    const handleOpen = () => {
        onOpen();
    }
    const pages = useMemo(() => {
        return totalData ? Math.ceil(totalData / utils().pagination) : 0;
    }, [totalData, utils().pagination]);

    const typesOnly = typeTransactions.filter(item => item.type !== "").map(item => item.type);
//////////////////////////////////////// GLOBALS: END

    useEffect(() => {
        handleSubmit();
    }, [page, userInfo]);

//////////////////////////////////////// FILTER: START
    const [formState, setFormState] = useState({
        reference: startReference,
        type: startType,
    });

    const handleReference = (value) => {
        setFormState(prevState => ({...prevState, reference: value}));
    };

    const handleType = (e) => {
        setFormState(prevState => ({...prevState, type: e.target.value}));
    };

    const handleFromChange = (date) => {
        const isInvalid = endDate < date;

        setStartDate(date);
        setInputStatus(isInvalid);
        setDisabledBtn(isInvalid);
    };

    const handleToChange = (date) => {
        const isValid = date >= startDate;
        setEndDate(date);
        setInputStatus(!isValid);
        setDisabledBtn(!isValid);
    };
//////////////////////////////////////// FILTERS: END

//////////////////////////////////////// FUNCTIONS: START
    const handleSubmit = (event) => {
        if (event) event.preventDefault();

        if (useFilter && (!startDate || !endDate)) {
            toast.error('Los campos Desde y Hasta son obligatorios');
            return;
        }

        let refNo = formState.reference;
        let filterType = formState.type;
        let sinceDate = moment(startDate).format('YYYY-MM-DD');
        let untilDate = moment(endDate).format('YYYY-MM-DD');

        let API_transactions = endPointsHandler(userInfo,
            {
                refNo: refNo,
                filterType: filterType,
                sinceDate: sinceDate,
                untilDate: untilDate,
                page: page
            }).transactions;

        getTransactions(API_transactions, userInfo, setData, setTotalData, setLoadingTable, login);
    }

    async function downloadXML(uuid) {
        let API_XML = endPointsHandler(userInfo, uuid).getXML;

        setWaitingProcess(true);
        try {
            const response = await axios.get(API_XML,
                {
                    headers: { Authorization: `Bearer ${userInfo.token}` },
                });

            const data = response.data;
            const xmlData = data.data.xml;

            const blob = new Blob([xmlData], { type: 'application/xml' });

            const url = window.URL.createObjectURL(blob);
            saveAs(url, uuid+'.xml');
            setWaitingProcess(false);
            toast.success("Archivo procesado exitosamente");
        } catch (error) {
            console.error('Hubo un error al descargar el XML', error);
            setWaitingProcess(false);
            toast.error("Ocurrió un error al procesar el archivo");
        }
    }
//////////////////////////////////////// FUNCTIONS: END

//////////////////////////////////////// TABLE: START
    let columns = [
        {uid: "reference",      name: "Referencia"},
        {uid: "type",           name: "Tipo de Transacción"},
        {uid: "tran_date",      name: "Fecha"},
        {uid: "due_date",       name: "Fecha de Vencimiento"},
        {uid: "total_amount",   name: "Total"},
        {uid: "allocated",      name: "Saldo"},
        {uid: "curr_code",      name: "Moneda"},
        {uid: "trans_no",       name: "Acciones"},
    ];
    if (type === 2) {
        columns = columns.filter(column => column.uid !== 'type');
    }

    const renderCell = useCallback((item, columnKey) => {
        const cellValue = item[columnKey];

        let total = numDecimals(item.total_amount, userInfo.decimals);
        let balance = numDecimals(item.total_amount, userInfo.decimals) - numDecimals(item.allocated, userInfo.decimals);
        let uuid = item.uuid_sat_manual;
        let type = item.type;
        let currency = item.curr_code;
        let transNo = item.trans_no;
        let transType = item.type;
        let reference = item.reference;
        let customerId = item.debtor_no;

        switch (columnKey) {
            case "reference":
                return (
                    <div className="text-start">{cellValue}</div>
                );
            case "type":
                return (
                    <div className="text-start">{typeTransactions.find(transaction => transaction.type === cellValue)?.label}</div>
                );
            case "tran_date":
                return (
                    <div className="text-start">{moment(cellValue).format('YYYY-MM-DD')}</div>
                );
            case "due_date":
                return (
                    <div className="text-start">
                        {cellValue && cellValue !== "0000-00-00" ? moment(cellValue).format('YYYY-MM-DD') : ""}
                    </div>
                );
            case "total_amount":
                return (
                    <div className="text-end">{numFormat(total, userInfo.decimals)}</div>
                );
            case "allocated":
                return (
                    <div className="text-end">{numFormat(balance, userInfo.decimals)}</div>
                );

            case "curr_code":
                return (
                    <div className="text-start">{cellValue}</div>
                );
            case "trans_no":
                return (
                    <div className="flex justify-end gap-2">
                        { uuid ? (
                            <>
                                <Button isIconOnly color="danger" variant="flat" size="md"
                                        onClick={() => getTransactionsPDF(userInfo, 2, setWaitingProcess, null, transNo, transType)}>
                                    <FiletypePdf size={20}/>
                                </Button>
                                <Button isIconOnly color="success" variant="flat" size="md"
                                        onClick={() => downloadXML(uuid)}>
                                    <FiletypeXml size={20}/>
                                </Button>
                            </>
                        ) : (transNo && transType) && (
                            <>
                                <Button isIconOnly color="danger" variant="flat" size="md"
                                        onClick={() => getTransactionsPDF(userInfo, 2, setWaitingProcess, null, transNo, transType)}>
                                    <FiletypePdf size={20}/>
                                </Button>
                            </>
                        )}
                        { type === '10' && balance > 0 && ( // Mostrar el boton pagar solo en facturas: 10
                            <PaymentDropdown
                                userInfo={userInfo}
                                reference={reference}
                                customerId={customerId}
                                transNo={cellValue}
                                transType={type}
                                balance={balance}
                                currency={currency}
                                setWaitingProcess={setWaitingProcess}
                                setRef={setRef}
                                handleOpen={handleOpen}
                            />
                        )}
                    </div>
                );
            default:
                return cellValue;
        }
    }, []);
//////////////////////////////////////// TABLE: END

    return (
        <>
            {waitingProcess &&
                <LTemplate1 status="Obteniendo información"/>
            }
            <div className="flex flex-col gap-4">
                <div className="flex justify-between gap-3 items-end">
                    {useFilter ? (
                        <form onSubmit={handleSubmit} className="flex w-full flex-wrap md:flex-nowrap gap-4">
                            <Input
                                size="sm"
                                label="Referencia"
                                value={formState.reference}
                                onValueChange={handleReference}
                            />

                            <Select
                                size="sm"
                                label="Tipo de transacción"
                                className="max-w-xs"
                                value={formState.type}
                                onChange={handleType}
                                defaultSelectedKeys={[formState.type]}
                            >
                                {typeTransactions.map((item) => (
                                    <SelectItem key={item.value} value={item.value}>
                                        {item.label}
                                    </SelectItem>
                                ))}
                            </Select>

                            <div className="customDatePickerWidth">
                                <DatePicker
                                    selected={startDate}
                                    onChange={handleFromChange}
                                    isClearable={true}
                                    dateFormat="yyyy-MM-dd"
                                    customInput={<Input isReadOnly size="sm" label="Desde"/>}
                                />
                            </div>

                            <div className="customDatePickerWidth">
                                <DatePicker
                                    selected={endDate}
                                    onChange={handleToChange}
                                    isClearable={true}
                                    dateFormat="yyyy-MM-dd"
                                    customInput={<Input isInvalid={inputStatus} size="sm" label="Hasta"/>}
                                />
                            </div>

                            <Button size="lg"
                                    color="primary"
                                    type="submit"
                                    isDisabled={disabledBtn}
                            >
                                <SearchIcon/>
                            </Button>
                        </form>
                    ) : null}
                </div>
            </div>
            <Table
                isHeaderSticky
                selectionMode="single"
                bottomContent={
                    pages > 0 ? (
                        <div className="flex w-full justify-center">
                            <Pagination
                                isCompact
                                showControls
                                showShadow
                                color="primary"
                                page={page}
                                total={pages}
                                onChange={(newPage) => setPage(newPage)}
                            />
                        </div>
                    ) : null
                }
            >
                <TableHeader columns={columns}>
                    {(column) => (
                        <TableColumn key={column.uid} className="text-center">
                            {column.name}
                        </TableColumn>
                    )}
                </TableHeader>
                <TableBody
                    emptyContent={"No hay transacciones pendientes de pago"}
                    loadingContent={<Spinner/>}
                    loadingState={loadingTable}
                    items={data.filter(item => typesOnly.includes(item.type))}>
                    {(item) => (
                        <TableRow key={item.trans_no}>
                            {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
                        </TableRow>
                    )}
                </TableBody>
            </Table>

            <Modal backdrop="blur" isOpen={isOpen} onClose={onClose}>
                <ModalContent>
                    <ModalHeader className="flex flex-col gap-1">{ref.title}</ModalHeader>
                    <ModalBody>
                        <p style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>
                            {ref.text ? ref.text : 'No se encontró información'}
                        </p>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};