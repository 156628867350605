import {handlePayment} from "./handlePayment";
export const handlePaymentOptions = (option, reference, customerId, transNo, transType, balance, currency, userInfo, setWaitingProcess, setRef, handleOpen) => {
    const paymentType = option.type === 'transfer' ? "transfer" : "stripe";

    switch (option.type) {
        case 'cash':
            window.open(`${option.value}`, '_blank');
            break;
        case 'transfer':
        case 'oxxo':
        case 'card':
            handlePayment({
                type: paymentType,
                reference: reference,
                customerId: customerId,
                transNo: transNo,
                transType: transType,
                balance: balance,
                currency: currency,
                paymentMethod: option.value,
                host: userInfo.host,
                token: userInfo.token,
                setWaitingProcess,
                setRef,
                handleOpen
            });
            break;
        default:
            break;
    }
};