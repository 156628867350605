import React, {useEffect, useState} from "react";
import { Sidebar } from "./sidebar.styles";
import { SidebarItem } from "./sidebar-item";
import { SidebarMenu } from "./sidebar-menu";
import { useSidebarContext } from "../layout/layout-context";
import {Bank, House} from "react-bootstrap-icons";
import { useLocation } from 'react-router-dom';

export const SidebarWrapper = () => {
    const { collapsed, setCollapsed } = useSidebarContext();
    const location = useLocation();
    const [userInfo, setUserInfo] = useState([]);

    useEffect(() => {
        const userData = localStorage.getItem('operamUser');
        if (userData) {
            setUserInfo(JSON.parse(userData));
        }
    }, []);

    function isRoute(route) {
        return location.pathname === route;
    }

    return (
        <aside className="h-screen z-[20] sticky top-0">
            {collapsed ? (
                <div className={Sidebar.Overlay()} onClick={setCollapsed} />
            ) : null}
            <div
                className={Sidebar({
                    collapsed: collapsed,
                })}
            >
                <div className={Sidebar.Header()}>
                    <div className="flex items-center gap-2">
                        <div className="flex flex-col gap-4">
                            <h3 className="text-xl font-medium m-0 text-default-900 -mb-4 whitespace-nowrap">
                                {userInfo.company}
                            </h3>
                            <span className="text-xs font-medium text-default-500">
                                {userInfo.companyTax}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col justify-between h-full">
                    <div className={Sidebar.Body()}>
                        <SidebarItem
                            title="Inicio"
                            icon={<House/>}
                            href="/Inicio"
                            isActive={isRoute("/Inicio")}
                        />
                        <SidebarMenu title="General">
                            <SidebarItem
                                title="Transacciones"
                                icon={<Bank />}
                                href="/Transacciones"
                                isActive={isRoute("/Transacciones")}
                            />
                        </SidebarMenu>
                    </div>
                </div>
            </div>
        </aside>
    );
};