import numeral from 'numeral';

export function numDecimals(numero, decimals) {
    let format = '0.' + '0'.repeat(decimals);
    return numeral(numero).format(format);
}

export function numFormat(numero, decimals) {
    let format = '0,0.' + '0'.repeat(decimals);
    return numeral(numero).format(format);
}