import { TableTransactions } from "../../components/tableTransactions";
import React, {useEffect, useState} from "react";
import LTemplate1 from "../../../components/loading/template1";

const Transactions = () => {
    const [userInfo, setUserInfo] = useState(null);
    const [loadingPage, setLoadingPage] = useState(true);

    useEffect(() => {
        const userData = localStorage.getItem('operamUser');
        if (userData) {
            setUserInfo(JSON.parse(userData));
        }
        setLoadingPage(false); // Desactivar el indicador de carga una vez que se obtiene la información
    }, []);

    return (
        <>
            {loadingPage &&
                <LTemplate1 status="Loading"/>
            }
            {userInfo && (
                <>
                    <div className="my-14 lg:px-6 max-w-[95rem] mx-auto w-full flex flex-col gap-4">
                        <h3 className="text-xl font-semibold">Transacciones</h3>
                        <TableTransactions useFilter={true} type={1} userInfo={userInfo}/>
                    </div>
                </>
            )}
        </>
    );
};
export default Transactions;