import axios from "axios";
import {toast} from "react-toastify";

export const getTransactions = async (API_transactions, userInfo, setData, setTotalData, setLoadingTable, login) => {
    setLoadingTable("loading");
    try {
        const res = await axios.get(API_transactions, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`,
            },
        });
        setData(res.data.data);
        setTotalData(res.data.total);
        setLoadingTable("idle");
    } catch (errors) {
        setLoadingTable("idle");
        setData([])
        console.error(`Hubo un error al obtener las transacciones: ${errors}`);

        // Verifica si el error es un error de "Unauthorized"
        if (errors.response && errors.response.status === 401) {
            // Si es así, intenta obtener un nuevo token
            try {
                await login(userInfo, true);
                // Luego intenta obtener las transacciones de nuevo
                getTransactions(API_transactions, userInfo, setData, setTotalData, setLoadingTable, login);
            } catch (loginError) {
                console.error('Hubo un error al obtener un nuevo token:', loginError);
                toast.error("Hubo un error al obtener un nuevo token");
            }
        }
    }
};
